<template>
  <div id="app">
    <Home />
    <!-- <Serial /> -->
  </div>
  
</template>

<script>
import Home from './views/Home'
// import Serial from './views/Serial'
export default {
  name: 'App',

  components: {
    Home,
    // Serial,
  },

	mounted() {
		document.title = "投票器";
	}



}
</script>
