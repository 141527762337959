<template>
  <div class="home-container">
    <!-- <Serial /> -->
    <el-card v-if="full_version" shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        网络配置
      </div>
      <el-form ref="configForm" hide-required-asterisk size="small" label-position="top" :model="connection">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="ssid" label="WIFI">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-input v-model="connection.ssid"></el-input>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="connection.wifi_password" show-password></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="port" label="MQTT">
              <el-input v-model="connection.host"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="port" label="话题">
              <el-input v-model="subscription.topic"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="port" label="设备">
              <el-button type="primary" @click="connectPort">选择串口</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="port" label="下载配置">
              <el-button type="primary" @click='downloadESP32'>
                下载
              </el-button>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <!-- <div class="emq-title">
        Configuration
      </div> -->
      <el-form ref="configForm" hide-required-asterisk size="small" label-position="left" :model="connection">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="host" label="Host">

              <el-input v-model="connection.host"></el-input>

            </el-form-item>
          </el-col>

          <el-col :span="3">
            <el-form-item prop="port" label="Connect">
              <el-button type="success" size="small" class="conn-btn" style="margin-right: 20px;"
                :disabled="client.connected" @click="createConnection" :loading="connecting">
                {{ client.connected ? 'Connected' : 'Connect' }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="port" label="Disconnect" v-if="client.connected">
              <el-button type="danger" size="small" class="conn-btn" @click="destroyConnection">
                Disconnect
              </el-button>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </el-card>

    <el-card shadow="always" style="margin-bottom:30px;" v-if="client.connected">
      <div class="emq-title">
        课堂
      </div>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="success" size="small" class="class-btn" @click="onClassOn">
            上课
          </el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="danger" size="small" class="class-btn" @click="onClassOff">
            下课
          </el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        统计
      </div>
      <el-row>
        <el-col :span="4">
          <div class="title">
            当前阶段
          </div>
        </el-col>
        <el-col :span="2">
          <div class="title">
            {{ CurrentStep + 1 }}
          </div>

        </el-col>
        <el-col :span="4" :offset="1">
          <div class="title">
            在线设备数
          </div>
        </el-col>
        <el-col :span="2">
          <div class="title">
            {{ OnlineDevNum }}
          </div>
        </el-col>
      </el-row>


      <el-form ref="publish" hide-required-asterisk size="small" label-position="top" :model="publish">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="" label="阶段" width="180" type="index">
                <template slot-scope="scope">
                  <el-button type="success" size="small" class="conn-btn" @click="onStep(scope.$index)">阶段 {{
                    scope.$index + 1 }}
                  </el-button>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="Step" label="阶段" width="180" /> -->
              <el-table-column prop="P5" label="5分" />
              <el-table-column prop="P4" label="4分" />
              <el-table-column prop="P3" label="3分" />
              <el-table-column prop="P2" label="2分" />
              <el-table-column prop="P1" label="1分" width="180" />




            </el-table>
          </el-col>
        </el-row>
      </el-form>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-button :disabled="!client.connected" type="success" size="small" class="export-btn" @click="doExport">
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        Publish
      </div>
      <el-form ref="publish" hide-required-asterisk size="small" label-position="top" :model="publish">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="topic" label="Topic">
              <el-input v-model="publish.topic"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="payload" label="Payload">
              <el-input v-model="publish.payload"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="qos" label="QoS">
              <el-select v-model="publish.qos">
                <el-option v-for="qos in qosList" :key="qos" :label="qos" :value="qos"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-col :span="24">
        <el-button :disabled="!client.connected" type="success" size="small" class="publish-btn" @click="doPublish">
          Publish
        </el-button>
      </el-col>
    </el-card>
    <el-card shadow="always" style="margin-bottom:30px;">
      <div class="emq-title">
        Receive
      </div>
      <el-col :span="24">
        <el-input type="textarea" :rows="3" style="margin-bottom: 15px" v-model="receiveNews" readOnly></el-input>
      </el-col>
    </el-card>
    <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2024089685号</a>
  </div>
</template>

<script>
import mqtt from 'mqtt'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'
// import Serial from './Serial'

export default {
  name: 'Home',
  components: {
    // Serial,
  },

  data() {
    return {
      full_version: false,

      serial: {
        port: null,
        reader: null,
        writer: null,
        baudrate: 115200,
      },
      connection: {
        ssid: 'fitsir',
        wifi_password: 'songyuan',
        protocol: 'wss',
        host: 'fitsir.site',
        port: 8084,
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          'emqx_vue_' +
          Math.random()
            .toString(16)
            .substring(2, 8),
        // auth
        username: 'robot',
        password: 'robot',
      },
      subscription: {
        topic: 'robot/button',
        qos: 0,
      },
      publish: {
        topic: 'robot/button',
        qos: 0,
        payload: '{"ID":"1234","KEY":0}',
      },
      receiveNews: '',
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
      tableData: [
        {
          Step: '阶段1',
          P1: 0,
          P2: 0,
          P3: 0,
          P4: 0,
          P5: 0,
        }, {
          Step: '阶段2',
          P1: 0,
          P2: 0,
          P3: 0,
          P4: 0,
          P5: 0,
        }, {
          Step: '阶段3',
          P1: 0,
          P2: 0,
          P3: 0,
          P4: 0,
          P5: 0,
        }, {
          Step: '阶段4',
          P1: 0,
          P2: 0,
          P3: 0,
          P4: 0,
          P5: 0,
        }, {
          Step: '阶段5',
          P1: 0,
          P2: 0,
          P3: 0,
          P4: 0,
          P5: 0,
        },
      ],
      CurrentStep: 0,
      OnlineDevNum: 0,
      DevList: [],
      FullStatus: [],
      KeyNum: 5,
      StepNum: 5,
      port: null,
      reader: null,
      writer: null,
      message: '',
      receivedData: ''
    }
  },
  created() {

  },
  mounted() {
    // this.connection.host = this.esp32_connection.mqtt_broker
    this.resetTableData()
    this.createConnection()
    this.loadTableData()
    this.loadPersistedData()

  },
  methods: {
    loadPersistedData() {
      const devListData = localStorage.getItem('DevList')
      if (devListData) {
        this.DevList = JSON.parse(devListData)
        this.OnlineDevNum = this.DevList.length // 更新在线设备数
      }
    },
    savePersistedData() {
      localStorage.setItem('DevList', JSON.stringify(this.DevList))
      localStorage.setItem('OnlineDevNum', JSON.stringify(this.OnlineDevNum))
    },
    loadTableData() {
      const devList = localStorage.getItem('DevList')
      const data = localStorage.getItem('tableData')
      const devNum = localStorage.getItem('onlineDevNum')
      const timestamp = localStorage.getItem('tableDataTimestamp')
      const currentTime = new Date().getTime()

      if (data && timestamp && currentTime - parseInt(timestamp) < 3600000) {
        // 3600000 milliseconds = 1 hour
        this.devList = JSON.parse(devList)
        this.tableData = JSON.parse(data)
        this.OnlineDevNum = JSON.parse(devNum)
        console.log(this.tableData)
      } else {
        this.resetTableData()
      }
    },

    resetTableData() {
      this.DevList = []
      this.OnlineDevNum = 0
      this.savePersistedData() // 清空本地存储
      this.tableData = [
        { Step: '阶段1', P1: 0, P2: 0, P3: 0, P4: 0, P5: 0 },
        { Step: '阶段2', P1: 0, P2: 0, P3: 0, P4: 0, P5: 0 },
        { Step: '阶段3', P1: 0, P2: 0, P3: 0, P4: 0, P5: 0 },
        { Step: '阶段4', P1: 0, P2: 0, P3: 0, P4: 0, P5: 0 },
        { Step: '阶段5', P1: 0, P2: 0, P3: 0, P4: 0, P5: 0 },
      ]
      this.saveTableData() // Reset and save empty data with new timestamp
      this.OnlineDevNum = 0 // Reset online device count
      this.DevList = [] // Optionally clear the list of devices if applicable
      this.FullStatus = []
    },

    saveTableData() {
      console.log('save:', JSON.stringify(this.tableData))
      localStorage.setItem('tableData', JSON.stringify(this.tableData))
      localStorage.setItem('tableDataTimestamp', new Date().getTime().toString())
    },
    initData() {
      this.client = {
        connected: false,
      }
      this.retryTimes = 0
      this.connecting = false
      this.subscribeSuccess = false


    },
    handleOnReConnect() {
      this.retryTimes += 1
      if (this.retryTimes > 5) {
        try {
          this.client.end()
          this.initData()
          this.$message.error('Connection maxReconnectTimes limit, stop retry')
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    },
    createConnection() {
      try {
        this.connecting = true
        const { protocol, host, port, endpoint, ...options } = this.connection
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`
        this.client = mqtt.connect(connectUrl, options)
        if (this.client.on) {
          this.client.on('connect', () => {
            this.connecting = false
            console.log('Connection succeeded!')
            this.doSubscribe()
          })
          this.client.on('reconnect', this.handleOnReConnect)
          this.client.on('error', error => {
            console.log('Connection failed', error)
          })
          this.client.on('message', (topic, message) => {
            this.receiveNews = this.receiveNews.concat(message)
            console.log(`Received message ${message} from topic ${topic}`)

            // this.handleOnMessage(topic, message)
          })
          this.client.on('message', this.handleOnMessage)
        }
      } catch (error) {
        this.connecting = false
        console.log('mqtt.connect error', error)
      }
    },
    // subscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientsubscribetopictopic-arraytopic-object-options-callback
    doSubscribe() {
      // const { topic, qos } = this.connection
      let topic = this.subscription.topic
      let qos = this.subscription.qos
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
      })
    },
    // unsubscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientunsubscribetopictopic-array-options-callback
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
        }
      })
    },
    // publish message
    // https://github.com/mqttjs/MQTT.js#mqttclientpublishtopic-message-options-callback
    doPublish() {
      const { topic, qos, payload } = this.publish
      this.client.publish(topic, payload, { qos }, error => {
        if (error) {
          console.log('Publish error', error)
        }
      })
    },

    handleOnMessage(topic, message) {
      let msg = JSON.parse(message.toString(), null, 2)
      let id = msg['ID']
      let key = Number(msg['KEY'])
      if (key < 0 || key > 4) {
        return
      }

      console.log(topic, id, key)


      try {
        if (topic == this.subscription.topic) {

          if (!this.DevList.includes(id)) {
            this.DevList.push(id)
            this.OnlineDevNum = this.DevList.length // 更新在线设备数
            this.savePersistedData() // 保存更改
          }

          let devMap = this.FullStatus[this.CurrentStep]
          console.log(devMap)
          if (devMap == undefined) {
            devMap = {}
          }
          if (!(id in devMap)) {
            devMap[id] = key
            this.FullStatus[this.CurrentStep] = devMap
            console.log(this.FullStatus)

            if (key == 0) {
              console.log(key)
              this.tableData[this.CurrentStep].P5 += 1
            } else if (key == 1) {
              console.log(key)
              this.tableData[this.CurrentStep].P4 += 1
            } else if (key == 2) {
              console.log(key)
              this.tableData[this.CurrentStep].P3 += 1
            } else if (key == 3) {
              console.log(key)
              this.tableData[this.CurrentStep].P2 += 1
            } else if (key == 4) {
              console.log(key)
              this.tableData[this.CurrentStep].P1 += 1
            }
          }

        }


        this.OnlineDevNum = this.DevList.length
        // 保存tableData到LocalStorage
        localStorage.setItem('tableData', JSON.stringify(this.tableData))
        localStorage.setItem('devList', JSON.stringify(this.DevList))
        localStorage.setItem('onlineDevNum', JSON.stringify(this.OnlineDevNum))
        console.log('Saved tableData to localStorage:', this.tableData)

      } catch (error) {
        console.error('Error in handleOnMessage:', error)
      }

      // else {
      //   console.log(`Device ${id} is already logged in and cannot log in again.`)
      // }

    },

    doExport() {
      // 使用SheetJS库生成Excel文件
      const wb = XLSX.utils.book_new() // 创建一个新的工作簿
      const ws = XLSX.utils.json_to_sheet(this.tableData) // 将JSON数据转换为工作表
      const startTime = localStorage.getItem('classStartTime') // 获取时间

      const formattedTime = startTime
        .replace(/\//g, '-') // 将斜杠替换为连字符
        .replace(/:/g, '-') // 将冒号替换为连字符
        .replace(/(\d{4}-\d{2}-\d{2}) (\d{2})-(\d{2})-\d{2}/, '$1 $2-$3') // 重组字符串并去掉秒

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1') // 将工作表添加到工作簿

      // 将工作簿写入二进制格式
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

      // 创建Blob对象并指定文件类型
      const blob = new Blob([wbout], { type: 'application/octet-stream' })

      // 生成URL对象
      let urlObject = window.URL || window.webkitURL || window
      let url = urlObject.createObjectURL(blob)
      // 创建<a> DOM元素
      let el = document.createElement('a')
      el.href = url
      el.download = formattedTime + '上课记录.xlsx' // 指定导出的文件名
      el.click() // 触发点击事件，开始下载

      //释放资源
      urlObject.revokeObjectURL(url)
    },

    onStep(index) {
      console.log(index)
      this.CurrentStep = index
    },
    // disconnect
    // https://github.com/mqttjs/MQTT.js#mqttclientendforce-options-callback
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initData()
            console.log('Successfully disconnected!')
          })
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    handleProtocolChange(value) {
      this.connection.port = value === 'wss' ? '8084' : '8083'
    },
    onClassOn() {
      const now = new Date()
      const options = { timeZone: 'Asia/Shanghai', hour12: false }
      const beijingTime = new Intl.DateTimeFormat('zh-CN', {
        ...options,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(now)

      localStorage.setItem('classStartTime', beijingTime) // Store class start time in Beijing time
      this.classOn = true
      console.log('Class started at:', beijingTime)

    },
    onClassOff() {
      this.$confirm('请问确定要下课吗?', '下课', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
        .then(() => {
          const startTime = localStorage.getItem('classStartTime')
          const now = new Date()
          const options = { timeZone: 'Asia/Shanghai', hour12: false }
          const beijingTime = new Intl.DateTimeFormat('zh-CN', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now)

          console.log('Class started at:', startTime, 'and ended at:', beijingTime)
          this.resetTableData()
          this.receiveNews = '' // Clear the receive box
          this.classOn = false
          //localStorage.removeItem('classStartTime') // Clear class start time
        })
        .catch(() => {
          // handle cancel
        })
    },
    // 连接串口
    async connectPort() {
      try {
        // 如果未选择串口，或者需要重新选择串口
        if (!this.serial.port) {
          this.serial.port = await navigator.serial.requestPort();
        }

        // 检查串口是否已打开
        if (this.serial.port.readable || this.serial.port.writable) {
          console.log('串口已连接');
          this.$message.success('串口已连接');
        } else {
          // 尝试打开串口，并捕获可能的错误
          try {
            await this.serial.port.open({ baudRate: this.serial.baudrate });
            console.log('串口已成功连接');
          } catch (openError) {
            console.error('无法打开串口:', openError);
            this.$message.error('无法打开串口，请检查设备是否已被占用');
            return;
          }

          // 检查串口的 readable 和 writable 属性
          if (this.serial.port.readable && this.serial.port.writable) {
            console.log('串口读写流已初始化');
            this.$message.success('串口已成功连接并准备好通信');
          } else {
            console.error('串口读写流未初始化');
            this.$message.error('串口已连接，但无法通信');
          }
        }
      } catch (error) {
        if (error.name === 'NotFoundError') {
          console.error('未选择任何串口设备。');
          this.$message.error('未选择任何串口设备，请重试。');
        } else if (error.name === 'InvalidStateError') {
          console.error('串口已打开:', error);
          this.$message.error('串口已打开，无需重复连接。');
        } else {
          console.error('无法连接到串口设备:', error);
          this.$message.error('无法连接到串口设备，请检查连接并重试。');
        }
      }
    },

    // 发送消息
    async downloadESP32() {
      console.log('串口对象:', this.serial.port);
      console.log('串口可写流:', this.serial.port ? this.serial.port.writable : null);

      if (this.serial.port && this.serial.port.writable) {
        try {
          const writer = this.serial.port.writable.getWriter();
          console.log(this.connection.ssid)
          console.log(this.connection.wifi_password)
          console.log(this.connection.host)
          console.log(this.subscription.topic)
          await writer.write(new TextEncoder().encode(`ssid:${this.connection.ssid}\r\n`));
          await writer.write(new TextEncoder().encode(`password:${this.connection.wifi_password}\r\n`));
          await writer.write(new TextEncoder().encode(`mqtt_broker:${this.connection.host}\r\n`));
          await writer.write(new TextEncoder().encode(`mqtt_topic:${this.subscription.topic}\r\n`));
          writer.releaseLock();
          console.log('配置已发送');
          this.$message.success('配置已成功发送到设备');
        } catch (error) {
          console.error('发送配置时出错:', error);
          this.$message.error('发送配置时出错，请重试。');
        }
      } else {
        console.log('请先连接串口');
        this.$message.error('请先连接串口设备');
      }
    }
  },
}
</script>

<style lang="scss">
@import url('../assets/style/home.scss');

.emq-title {
  font-size: 20px;
  font-weight: 600;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.home-container {
  max-width: 1100px;
  margin: 0 auto;

  .conn-btn {
    color: #fff;
    background-color: #00b173;
    font-size: 14px;
  }

  .export-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
    width: 100px;
  }

  .class-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    // float: right;
    width: 100px;
  }


  .publish-btn {
    width: 100px;
    margin-bottom: 20px;
    float: right;
  }

  .el-button--success {
    background-color: #34c388 !important;
    border-color: #34c388 !important;
    font-size: 14px !important;
  }

  .el-button--danger {
    background-color: #f5222d !important;
    border-color: #f5222d !important;
  }

  .el-form-item {
    &.is-error {

      .el-input__inner,
      .el-textarea__inner {
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
    }

    &.is-success {

      .el-input__inner,
      .el-textarea__inner {
        border-color: #34c388 !important;
      }
    }
  }
}
</style>
